import React, {useEffect, useState} from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import BookCard from "../components/card/bookCard"
import BookLayout from "../components/layout/bookLayout"
import "../assets/scss/layout/pages/books.scss"

const BooksPage = ({data, path, location}) => {
  const content = data.allWpBook.nodes || []

  const [books, setBooks] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedTags, setSelectedTags] = useState([])
  const [searchedValue, setSearchedValue] = useState("")

  useEffect(() => {
    if (location && location.state) {
      setSelectedCategory(location.state.category)
    }
  }, [location])

  useEffect(() => {
    setBooks(content)
  }, [content])

  useEffect(() => {
    let result = [...content]

    if ("" !== selectedCategory && selectedCategory && selectedCategory.length) {
      result = [...result.filter(book => book.categories.nodes.map(({id}) => id).includes(selectedCategory))]
    }

    if ("" !== searchedValue) {
      result = [...result.filter(book => book.title.toLowerCase().includes(searchedValue.toLowerCase()))]
    }

    if (selectedTags.length) {
      result = [...result.filter(book => (
        !selectedTags.map(tag => book.bookTags.nodes.map(({id}) => id).includes(tag)).includes(false)
      ))]
    }

    setBooks(result)
  }, [selectedCategory, selectedTags, searchedValue])

  return (
    <Layout mt={true} mb={true} path={path}>
      <SEO title="Books" />
      <BookLayout
        getCategory={c => setSelectedCategory(c)}
        getTags={t => setSelectedTags(t)}
        getSearch={s => setSearchedValue(s)}
        location={location}
      >
        {books.map(book => <BookCard key={book.id} book={book} />)}
      </BookLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpBook(sort: {order: DESC, fields: modified}) {
      nodes {
        id
        title
        content
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(sizes: "300") {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        categories {
          nodes {
            id
            name
            slug
          }
        }
        bookTags {
          nodes {
            id
            name
            slug
          }
        }
      }
    }
  }
`

export default BooksPage
